import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {CardBody} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import {useLocation} from "react-router-dom";
import {IStock} from "../../model/Stock";
import stockService from "../../service/StockService";
import Layout from "../../components/layout/Layout";
import CryptoComponent from "../../components/crypto/CryptoComponent";
import {cacheExchange, createClient, fetchExchange, Provider} from "urql";


const apiKey = 'd59545755cf1974d72a4b387c64e85e9'

const client = createClient({
    url: `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/HUZDsRpEVP2AvzDCyzDHtdc64dyDxx8FQjzsmqSg4H3B`,
    exchanges: [cacheExchange, fetchExchange],
});


export const CryptoPage = () => {


        const content =
            <>
                <Provider value={client}>
                    <CryptoComponent/>
                </Provider>
            </>


        return (
            <>
                <Layout>
                    <Row className="g-3">
                        <Col sm={12}>

                            <Card>
                                <CardHeader>
                                    <h6>My crypto</h6>
                                </CardHeader>

                                <CardBody className="">

                                    {content}

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Layout>
            </>
        );
    }
;
