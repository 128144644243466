import axios from "axios";
import {ISearchResponse} from "../../model/Search";
import {cacheExchange, createClient, fetchExchange, useQuery} from "urql";
import {IGraphData} from "../../model/crypto/Graph";
import {Provider} from "react";


const apiKey = 'd59545755cf1974d72a4b387c64e85e9'

const client = createClient({
    url: `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/HUZDsRpEVP2AvzDCyzDHtdc64dyDxx8FQjzsmqSg4H3B`,
    exchanges: [cacheExchange, fetchExchange],
});


class CryptoService {

    getClient() {
        return client
    }

    getData(query: string) {
        const [result] = useQuery<IGraphData>({ query: query });
        const { data, fetching, error } = result;
        return result.data;
    }

  getVolume() {

  const QUERY = `{
  poolDayDatas(orderDirection: desc, where: {date_gt: 10}) {
    date
    feesUSD
    high
    low
    volumeUSD
  }
  bundles(first: 5) {
    id
    ethPriceUSD
  }
}`;

        const [result] = useQuery<IGraphData>({ query: QUERY });
        const { data, fetching, error } = result;

        return result.data?.poolDayDatas[0].volumeUSD;

    }


}

export default new CryptoService();