import React, {useEffect, useMemo, useState} from "react";
import Layout from "../../components/layout/Layout";
import SearchService from "../../service/SearchService";
import {IPosition} from "../../model/Positions";
import positionsService from "../../service/PositionsService";
import {IUser} from "../../model/User";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Button, CardBody, Image, Modal, Table} from "react-bootstrap";

import avatar from "../../assets/images/user/default_avatar.png";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import SignInComponent from "../../components/public/sign-in/SignInComponent";
import {Link, useLocation} from "react-router-dom";
import Utils from "../../service/utils/Utils";
import {
    ColumnDef,
    createColumnHelper,
    flexRender,
    getCoreRowModel, GroupColumnDef,
    useReactTable,
} from '@tanstack/react-table'


export const PositionsPage = () => {

    const [positions, setPositions] = useState<IPosition[]>([])
    const [showLogin, setShowLogin] = useState(false);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);
    const location = useLocation();


    useEffect(() => {

            positionsService.getAllForCurrentUser()
                .then(response => {
                    setPositions(response.data)
                    //console.log("positions:: " + positions)
                })
        }, []
    )


    const columnHelper = createColumnHelper<IPosition>()


    const cols = useMemo<ColumnDef<IPosition>[]>(
        () => {
            return [
                {
                    header: 'Stock',
                    id: 'stock',
                    accessorKey: 'symbol',
                    cell: (row) => row.renderValue(),
                },
                {
                    header: 'Price Paid',
                    cell: (row) => row.renderValue(),
                    accessorKey: 'price',
                    id: 'price',
                },
                {
                    header: () => <span>Market value</span>,
                    accessorFn: row => Utils.formatPrice(row.currentPrice * row.quantity),
                    cell: info => info.getValue(),
                    id: 'marketPrice',
                },
                {
                    header: 'Quantity',
                    cell: (row) => row.renderValue(),
                    accessorKey: 'quantity',
                },

                {
                    header: 'Total cost',
                    accessorFn: row => {
                        Utils.formatPrice(row.price * row.quantity)
                    },
                    cell: (row) => row.renderValue(),
                },
                {
                    header: 'Gain/Loss',
                    accessorFn: (row) => {
                        (row.currentPrice * row.quantity) - (row.price * row.quantity)
                    },
                    cell: (row) => row.renderValue(),
                },
                columnHelper.display({
                    header: 'Actions',
                    id: 'actions',
                    cell: props => <>
                        <Button variant="success" size="sm" className="me-2"
                                onClick={(event) => {
                                    event.preventDefault()
                                    /*  handleRemoveModalShow(u)*/
                                }}
                        >Buy</Button>
                        <Button variant="danger" size="sm"
                                onClick={(event) => {
                                    event.preventDefault()
                                    /*  handleRemoveModalShow(u)*/
                                }}
                        >Sell</Button>
                    </>,
                }),

            ];
        },
        []
    );


    const data: IPosition[] = positions;
    const columns = cols;
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });


    const render = <>

        <Table hover responsive>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>

        </Table>

    </>


    const content =
        <>
            <Modal show={showLogin} onHide={handleCloseLogin} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Please sign-in to add comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="m-1 m-md-3 px-2 px-md-4"><SignInComponent redirectTo={location.pathname}/></div>
                </Modal.Body>
            </Modal>


            <Row className="g-3">
                <Col sm={12}>

                    <Card>
                        <CardHeader>
                            <h6>My positions</h6>
                        </CardHeader>

                        <CardBody className="">

                            {render}

                            {/*{renderPositions1}*/}


                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </>


    return (
        <>
            <Layout>
                {content}
            </Layout>
        </>
    )


}
