import * as React from 'react';
import {NavLink, NavLinkRenderProps} from 'react-router-dom';
import './Sidebar.scss';
import {FaChartBar, FaListUl, FaRegComment, FaRegCommentAlt, FaRegUser} from "react-icons/fa";
import {RxDashboard} from "react-icons/rx";
import {SlWallet} from "react-icons/sl";
import {FiUsers, FiMessageCircle} from "react-icons/fi";
import {MdOutlineSsidChart} from "react-icons/md";
import {GrBarChart} from "react-icons/gr";
import Card from "react-bootstrap/Card";
import {NavItem} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {GiCryptEntrance} from "react-icons/gi";
import {RiCoinsFill, RiCoinsLine} from "react-icons/ri";
import {BsCoin} from "react-icons/bs";


function Sidebar() {

    function navLinkClass(isActive: NavLinkRenderProps) {
        return " " + isActive ? "nav-link active" : "nav-link"
    }

    return (

        /*<div className="sidebar-main d-flex flex-column flex-shrink-2">*/
        <Card border="0" className="d-flex flex-column flex-shrink-0 p-2 sticky-top" style={{top: 66}}>
            <Navbar sticky="top" expand="lg" className="p-0 ">
                <Nav className=" sidebar nav-pills sticky-top flex-column mb-auto w-100">
                    <NavItem as="li" className="mw-100 p-1">
                        <NavLink to="/dashboard"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <RxDashboard className="ms-1"/>
                            Dashboard
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/profile"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <FaRegUser className="ms-1"/>
                            My Profile
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/positions"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <MdOutlineSsidChart className="ms-1"/>
                            My Stocks
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/crypto"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <BsCoin className="ms-1"/>
                            Crypto
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/connections"
                                 className={({isActive}) => (isActive ? "nav-link active" : "nav-link")}>
                            <FiUsers className="ms-1"/>
                            Connections
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/messages"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <FaRegComment className="ms-1"/>
                            Messages
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/stock-market"
                                 className={({isActive}) => (isActive ? "nav-link  active" : "nav-link ")}>
                            <GrBarChart className="ms-1"/>
                            Stock Market
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/wallet"
                                 className={({isActive}) => (isActive ? "nav-link active" : "nav-link")}>
                            <SlWallet className="ms-1"/>
                            My Wallet
                        </NavLink>
                    </NavItem>
                    <NavItem as="li" className="mw-100 p-1">
                        <NavLink to="/watchlist"
                                 className={({isActive}) => (isActive ? "nav-link active" : "nav-link")}>
                            <FaListUl className="ms-1"/>
                            Watchlist
                        </NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
        </Card>

        /*      <hr/>
              <div className="dropdown">
                  <a href="#" className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
                     id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="https://github.com/mdo.png" alt="" width="32" height="32"
                           className="rounded-circle me-2"/>
                      <strong>mdo</strong>
                  </a>
                  <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                      <li><a className="dropdown-item" href="#">New project...</a></li>
                      <li><a className="dropdown-item" href="#">Settings</a></li>
                      <li><a className="dropdown-item" href="#">Profile</a></li>
                      <li>
                          <hr className="dropdown-divider"/>
                      </li>
                      <li><a className="dropdown-item" href="#">Sign out</a></li>
                  </ul>
              </div>*/
        /* </div>*/
    );
}


export default Sidebar;
