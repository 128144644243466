import React, {createContext, useEffect, useState} from 'react';
import './App.scss';
import {Route, Routes} from 'react-router-dom';
import Profile from "./components/profile/ProfileComponent";
import {Toaster} from "react-hot-toast";
import NotFound from "./components/error-pages/NotFound";
import InfoComponent from "./components/info/InfoComponent";
import FaqComponent from "./components/public/faq/FaqComponent";
import OfflineComponent from "./components/public/offline/OfflineComponent";
import HelpCenterComponent from "./components/public/help-center/HelpCenterComponent";
import LogoutComponent from "./components/routing/LogoutComponent";
import ProfileEditComponent from "./components/profile/edit/ProfileEditComponent";
import StockPage from "./components/stock/StockPage";
import WatchlistComponent from "./components/watchlist/WatchlistComponent";
import ConnectionsPage from "./components/user/ConnectionsPage";
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'
import SearchPage from "./pages/search/SearchPage";
import SignInPage from "./pages/public/sign-in/SignInPage";
import SignUpPage from "./pages/public/sign-up/SignUpPage";
import Homepage from "./pages/public/homepage/Homepage";
import PublicRoutes from "./components/routing/PublicRoutes";
import ProtectedRoutes from "./components/routing/ProtectedRoutes";
import DashboardPage from "./pages/DashboardPage";
import OAuth2RedirectHandler from "./components/OAuth2RedirectHandler";
import PrivacyPolicyPage from "./pages/public/privacy-policy/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/public/terms-and-conditions/TermsAndConditionsPage";
import StocksPage from "./pages/StocksPage";
import {CookiesProvider, useCookies} from "react-cookie";
import Toast from "react-bootstrap/Toast";
import {Button} from "react-bootstrap";
import {PositionsPage} from "./pages/positions/PositionsPage";
import {CryptoPage} from "./pages/crypto/CryptoPage";


const queryClient = new QueryClient()
type ThemeContextType = "light" | "dark";
type ContextType = {
    theme: string,
    setTheme: (theme: string) => void
};

export const ThemeContext = createContext<ContextType | undefined>(undefined);

function App() {
    const [theme, setTheme] = useState<ContextType["theme"]>("light");
    const [cookie, setCookie, removeCookie] = useCookies(['theme']);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTheme(cookie.theme === 'dark' ? 'dark' : 'light')
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeContext.Provider value={{theme, setTheme}}>
                <CookiesProvider/>
                <section className="bg-body-tertiary" data-bs-theme={theme}>

                    <Toaster gutter={15} position="top-center" containerStyle={{top: '65px'}}/>

{/*                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">Bootstrap</strong>
                            <small>11 mins ago</small>
                        </Toast.Header>
                        <Toast.Body>Woohoo, youre reading this text in a Toast!</Toast.Body>
                    </Toast>
                    <Button onClick={() => setShow(true)}>Show Toast</Button>*/}

                    <Routes>
                        <Route element={<PublicRoutes redirect="/profile"/>}>
                            <Route path='/' element={<Homepage/>}></Route>
                            <Route path='/home' element={<Homepage/>}></Route>
                            <Route path='/sign-in' element={<SignInPage/>}></Route>
                            <Route path='/sign-up' element={<SignUpPage/>}></Route>
                        </Route>

                        <Route element={<ProtectedRoutes redirect="/sign-in"/>}>
                            <Route path='/dashboard' element={<DashboardPage/>}></Route>
                            <Route path='/positions' element={<PositionsPage/>}></Route>
                            <Route path='/profile' element={<Profile/>}></Route>
                            <Route path='/profile/edit' element={<ProfileEditComponent/>}></Route>
                            <Route path='/watchlist' element={<WatchlistComponent/>}></Route>
                            <Route path='/connections' element={<ConnectionsPage/>}></Route>
                        </Route>
                        <Route path='/stock/:symbol?' element={<StockPage/>}></Route>
                        <Route path='/stock-market' element={<StocksPage/>}></Route>
                        <Route path='/crypto' element={<CryptoPage/>}></Route>
                        {/*<Route path='/chart' element={<ApexChartComponent/>}/>*/}
                        <Route path='/user/:id?' element={<Profile/>}></Route>
                        {/* <Route path='/home' element={<Homepage/>}></Route>*/}
                        <Route path='/search' element={<SearchPage/>}></Route>
                        <Route path='/info' element={<InfoComponent/>}/>
                        <Route path='/faq' element={<FaqComponent/>}/>
                        <Route path='/help' element={<HelpCenterComponent/>}/>
                        <Route path='/privacy-policy' element={<PrivacyPolicyPage/>}/>
                        <Route path='/terms-and-conditions' element={<TermsAndConditionsPage/>}/>
                        <Route path='/offline' element={<OfflineComponent/>}/>
                        <Route path='/logout' element={<LogoutComponent/>}/>
                        <Route path='/oauth2/redirect' element={<OAuth2RedirectHandler/>}/>
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>

                </section>
            </ThemeContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
