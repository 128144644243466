import {cacheExchange, createClient, fetchExchange, Provider, useQuery} from 'urql';
import {IGraphData} from "../../model/crypto/Graph";
import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Slider} from "@mui/material";
import {Form} from "react-bootstrap";
import CryptoService from "../../service/crypto/CryptoService";
import StockService from "../../service/StockService";


const QUERY = `{
  poolDayDatas(orderDirection: desc, where: {date_gt: 10}) {
    date
    feesUSD
    high
    low
    volumeUSD
  }
  bundles(first: 5) {
    id
    ethPriceUSD
  }
}`;


const CryptoComponent = () => {
    /*const [result] = useQuery<IGraphData>({ query: QUERY })*/

    const volume = CryptoService.getVolume()
    const data = CryptoService.getData(` {
  pools(where: {liquidity_gt: "0"}) {
    token1 {
      name
      symbol
    }
    token0 {
      name
      symbol
    }
    liquidity
    volumeUSD
  }
}`)


    function valuetext(value: number) {
        return `${value}°C`;
    }

    const minDistance = 1;


    const [value, setValue] = useState<number[]>([20, 37]);


    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValue([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue([clamped - minDistance, clamped]);
            }
        } else {
            setValue(newValue as number[]);
        }
    };


    return (
        <>
                <Row>
                    <Col sm={12}>
                        <Slider
                            getAriaLabel={() => 'Minimum distance shift'}
                            value={value}
                            onChange={handleChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            shiftStep={1}
                            disableSwap
                        />

                    </Col>


                    <Col sm={12}>
                        Min: {value[0]}
                    </Col>

                    <Col sm={12}>
                        Max: {value[1]}
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com"/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>USD Volume</Form.Label>
                                {/*<Form.Control as="textarea" rows={1} value={data.factories[0].totalVolumeUSD}/>*/}
                                <Form.Control as="textarea" rows={20}
                                              value={JSON.stringify(data, null, 2)}/>
                            </Form.Group>
                            Volume = {volume}
                        </Form>
                    </Col>
                </Row>

        </>
    )

}


export default CryptoComponent;